.admins_layout_container {
  position: relative;
  padding: 40px;
  width: 100%;
  height: 100vh;
  background-image: url(https://github.com/ecemgo/mini-samples-great-tricks/assets/13468728/5baf8325-ed69-40b0-b9d2-d8c5d2bde3b0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  display: flex;
}

.admins_subcontainer {
  min-width: 900px;
  width: 100%;
  max-width: 1400px;
  height: 90vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.admin_heading {
  display: flex;
  justify-content: space-between;
  background-color: var(--darkGray);
  /* border: 5px solid var(--darkGray); */
  border-radius: 8px 8px 0 0;
  /* color: white; */
  margin-bottom: 4px;
  padding: 5px 10px;

}

.admin_heading .invitation_form_contain {
  position: fixed;
  top: 0px;
  right: 0px;
  transform: translateX(0%);
  width: 100%;
  height: 100%;
  background-color: #000000a7;
  z-index: 10;
  display: flex;
}

.admin_heading .logo_and_search {
  display: flex;
  gap: 20px;
  padding: 2px 0px;
}

.admin_heading .Invitation_form {
  position: relative;
  margin: auto;
  transform: translateX(0%);
  top: unset;
  left: unset;
}

.admins_layout_container h1 {
  margin-top: 15px;
  margin-bottom: 50px;
  text-align: center;
  font-family: var(--fontDash);
  color: rgb(101, 101, 101);
}

.Invitation_form_container {
  position: relative;
  position: fixed;
  background-color: rgb(42, 128, 203);
  width: 100%;
  height: 100vh;
}



.admin_Log_out {
  margin: auto;
  margin-top: 1px;
  background-color: rgb(94, 0, 176);
  color: rgb(255, 255, 255);
  min-width: 80px;

  padding: 3px 12px;
  height: 28.5px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
}

.admin_Log_out:hover {
  background-color: rgb(40, 0, 75);
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
}

.admins_layout {
  margin: auto;
  width: 100%;
  height: 420px;
  height: 100%;
  display: flex;
  justify-content: left;
}

.admins_outlet {
  position: relative;
  border-left: none;
  background-color: white;
  /* width: 700px;
  width: fit-content; */
  padding: 10px;
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 0 16px 16px 0;
  /* justify-content: space-between; */
  gap: 10px;
}


.close_invitation {
  position: absolute;
  top: 15px;
  right: 15px;
  min-width: 30px;
  min-height: 30px;
  background-color: #c43cff24;
  border-radius: 50%;
}

.close_invitation:hover {
  background-color: #c43cff;
}

.admins_outlet aside {
  /* position: fixed; */
  border-radius: 10px;
  padding: 5px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
}


.add-someone-in-invite-form {
  margin: auto;
  margin-top: 1px;
  border-radius: 6px;

  background-color: #ffffff;
  border: 1px solid rgb(192, 192, 192);
  height: 28.5px;
  min-width: 140px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: rgb(119, 119, 119);
  margin-left: 10px;
  padding: 2px 10px;
  cursor: pointer;
  white-space: nowrap;
}

.add-someone-in-invite-form:hover {
  background-color: #a0ffa6;
  /* border: 1px solid green; */
  color: rgb(90, 90, 90);
}

.header_btn_container {
  display: flex;
  gap: 10px;
  /* margin-right: 20px; */
  /* z-index: 0; */
}


.header_btn_container .add-someone:hover {
  background-color: rgb(77, 255, 77);
  color: rgb(78, 72, 0);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}



.admins_layout .search_results {
  position: absolute;

  top: 65px;
  left: 0px;
  margin-left: 0px;
  width: 100%;

  height: 97% !important;
  max-height: 97% !important;
  border: none !important;
  background-color: rgb(255, 255, 255);
  /* background-color: rgb(232, 34, 34); */
  overflow: scroll;

  display: flex;
  flex-direction: column;

  z-index: 0;
}

.admins_layout .no-content {
  position: relative;
  border: none !important;
  width: 0px !important;
  height: 0px !important;
  padding: 0px;
}

.s_results_subcontainer {
  position: fixed;
  top: 110px;
  width: 1230px;
  height: 70% !important;
  border-radius: 0 0 8px 8px;
  /* background-color: #1183d6; */
  padding: 10px 0px;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  gap: 1.7px;
}

.admins_layout .search_results .click_to_Profile {
  padding: 5px 20px;
  cursor: pointer;
  width: 100%;

  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
}

.admins_layout .search_results .click_to_Profile:hover>div {
  color: rgb(152, 9, 255) !important;
  background-color: #e3ffe5;
  /* background-color: #ffffff; */
  box-shadow: 0 0 5px 0 #1183d6;
  transition: all 0.4s ease-in-out;
}

.admins_layout .search_results .firstRow {
  box-shadow: none !important;
  cursor: unset !important;
}

.admins_layout .search_results .firstRow:hover>div {
  box-shadow: none;
  color: rgb(255, 255, 255) !important;

}

.firstRow>div {
  color: white !important;
  background-color: var(--bgGray) !important;
  border: 1px solid var(--bgGray) !important;
  box-shadow: none !important;

}


.admins_layout .search_results .click_to_Profile>div {
  border: none;
  color: rgb(56, 56, 56);
  border-radius: 6px;
  padding: 2.5px 10px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: scroll;
  width: 260px !important;
  max-width: 260px !important;
  background-color: #f1fffe;
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
}

/* .admins_layout #image_container {
  margin-top: 3px;
  min-width: 30px !important;
  width: 30px !important;
  max-width: 30px !important;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  overflow: hidden;
  padding: 0px;
}

.admins_layout .image_container img {
  width: 100%;
  height: 100%;
} */


.admins_layout .search_results .click_to_Profile .dashboard-ordinals {
  min-width: 41px !important;
  width: 41px !important;
  padding: 4px;
  display: flex;
}

.admins_layout .search_results .click_to_Profile .dashboard-ordinals>div {
  margin: auto;
}

.admins_layout .search_results .click_to_Profile .dashboard-names {
  min-width: 220px !important;
  width: 220px !important;
  max-width: 220px !important;
  display: flex;
}

.admins_layout .search_results .click_to_Profile .dashboard-occupation {
  min-width: 180px !important;
  width: 180px !important;
  max-width: 180px !important;
}

.admins_layout .search_results .click_to_Profile .dashboard-level {
  min-width: 100px !important;
  width: 100px !important;
  max-width: 100px !important;
}

.admins_layout .search_results .click_to_Profile .dashboard-age {

  min-width: 70px !important;
  width: 70px !important;
  max-width: 70px !important;
}

.admins_layout .currently_logged {
  /* position: absolute;
  top: 10px; */
  border: 1px solid rgb(189, 189, 189);
  margin: auto;
  margin-top: 1px;
  margin-left: 5px;
  font-weight: 500;
  /* text-transform: uppercase; */
  height: 28.5px;
  padding: 5px 10px;
  border-radius: 6px;
  color: #7100a1;
  white-space: nowrap;

}

.admins_layout .currently_logged span {
  color: gray;
}


@media only screen and (max-width: 1480px) {
  .s_results_subcontainer {
    width: 1100px;
  }
}

@media only screen and (max-width: 1350px) {
  .s_results_subcontainer {
    width: 900px;
  }
}

@media only screen and (max-width: 1150px) {
  .s_results_subcontainer {
    width: 800px;
  }

  .admins_layout .search_results .click_to_Profile .dashboard-age {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .s_results_subcontainer {
    width: 700px;
  }

  .admins_layout .search_results .click_to_Profile .dashboard-occupation {
    display: none;
  }
}