.warnung_outer_contain {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  /* background-color: rgba(121, 121, 121, 0.796); */
  display: flex;
}

.warnung_case {
  position: relative;
  margin: auto;
  border-radius: 20px;
  /* width: 75%;
  height: 95%; */
  width: 80%;
  height: 95%;
  background: rgba(0, 0, 0, 0.942);
}

.Warning-dialogue-box-subcontainer {
  position: relative;
  margin: auto;
  margin-left: 0px;
  border-radius: 12px;
  width: 100%;
  height: 100%;


  background-image: url('../images/Lord_of_the_Ring.jpeg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;

  display: flex;
}

.Warning-dialogue-box {
  position: relative;
  margin: auto;
  margin-left: 60px;
  padding: 30px;
  /* border: 1px solid gray; */
  /* background-color: rgba(255, 255, 255, 0.558); */
  width: 250px;
  /* min-width: 450px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.Warning-dialogue-box .are_u_sure_to_remove {
  margin-bottom: 30px;
}

.Warning-dialogue-box .are_u_sure_to_remove h3 {
  /* text-align: center; */
  /* text-transform: uppercase; */
  color: rgb(255, 255, 255);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 10px;
}

.Warning-dialogue-box .are_u_sure_to_remove p {
  font-weight: 400;
  color: rgb(241, 232, 232);
}


.warnung_case .red-btn,
.warnung_case .no-btn {
  position: absolute;
  top: 140px;
  left: 30px;
  min-width: 120px;
  width: 120px;
}

.warnung_case .no-btn {
  top: 200px;
}

.warnung_case .red-btn {
  color: white;
  padding: 4px 15px;

  background-color: red;
  border: none;
  cursor: pointer;
}

.warnung_case .red-btn:hover {
  background-color: rgb(155, 0, 0);
  border: none;
}


.warnung_case .no-btn {
  color: white;
  padding: 4px 15px;

  background-color: rgb(21, 255, 0);
  border: none;
  cursor: pointer;
}

.warnung_case .no-btn:hover {
  background-color: rgb(15, 168, 2);
  border: none;
}


.Warning-dialogue-box .radio-btn p {
  color: red;
}

.warnung_case .close_it {
  position: absolute;

  top: 10px;
  right: 10px;
  padding: 4px !important;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: yellow;

  background-color: rgb(51, 22, 51);
  border: none;
  cursor: pointer;
  z-index: 1;

}

.warnung_case .close_it:hover {
  color: black;
  background-color: transparent !important;
  background-color: rgb(250, 255, 194) !important;
  border: none;
}

.warnung_case .include_publications {
  margin-top: 120px;
}

.warnung_case .include_publications .radio-label-container {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.warnung_case .include_publications .radio-label {
  /* margin-left: 10px; */
  display: flex;
  justify-content: left;
  gap: 10px;
}


.warnung_case .error,
.warnung_case .sadNews {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 255);
  z-index: 3;
  display: flex;
}

.warnung_case .error>div,
.warnung_case .sadNews>div {
  position: relative;
  margin: auto;
  width: 950px;
  height: 600px;

  border-radius: 12px;
  padding: 20px;
  background-color: rgba(19, 19, 19, 0.863);
  display: flex;
  flex-direction: column;
}

.warnung_case .sadNews>div {
  background-color: rgba(54, 23, 23, 0.863);
}

.warnung_case .error>div>div,
.warnung_case .sadNews>div>div {
  margin: auto;
  color: white;
}

.warnung_case .notifying_messages .sadNews {
  background-color: rgba(62, 0, 106, 0.891);
  color: blueviolet;
}

.warnung_case .bigger_font {
  color: rgb(255, 0, 0);
  text-align: center;
  font-size: 26px;
  font-weight: 300;
  line-height: 38px;
}

.warnung_case .smaller_font {
  text-align: center;

  font-size: 22px;
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.moveable-component {
  transition: transform 0.3s ease;
}