/* General styles  */
.from_askSupport .flex_it {
  display: flex;
}

.from_askSupport .space_between {
  display: flex;
  justify-content: space-between;
}

.from_askSupport .golden_heading {
  font-family: 'Silom';
  color: var(--colorDash);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.from_askSupport .bio .golden_heading {
  margin-bottom: 0px;
}


/* main_parts_of_code  */
.from_askSupport {
  /* background-color: rgb(255, 233, 233); */
  margin-top: 12px;
  padding: 0px 10px;
}

.from_askSupport header {
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); */
  width: fit-content;
  padding: 10px;
  border-radius: 7px;

  display: flex;
  gap: 15px;
}

.from_askSupport header p {
  font-family: 'Silom';
  color: var(--colorDash);

  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.from_askSupport .mapping_part {
  position: relative;
  margin: 20px 0px;
  padding: 10px;
  /* border: 1px solid gray; */
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.from_askSupport .left_wing {
  max-width: 300px;
  background: #f6f7fb;
  border-radius: 15px;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}


.from_askSupport .upper_part {
  /* height: 100%; */
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.from_askSupport .profile_picture {
  min-width: 100px;
  width: 100px;
  height: 100px;
}

.from_askSupport .profile_picture img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.from_askSupport .gegevens {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.from_askSupport .user_nick {
  font-family: 'Silom';
  color: var(--colorDash);
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}



.from_askSupport .searchName {
  display: inline-block;
  padding-top: 2px;
  width: 100%;
  color: green;
  font-size: 14px;
  font-weight: 300;
}

.from_askSupport .bio {
  z-index: 1;
}

.from_askSupport .lower_part {
  position: relative;
  max-height: 100px;
  overflow: scroll;
}

.from_askSupport .middle_part {
  border-radius: 15px;
  background: #f6f7fb;
  padding: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.from_askSupport .his_message {
  flex-grow: 3;
}

.from_askSupport .reviews,
.from_askSupport .admins_reply button {
  min-width: 100px;
  font-size: 10px;
  padding: 0px;
  height: 20px;
  background-color: rgb(184, 184, 184);
}

.from_askSupport .admins_reply button:hover {
  background-color: rgb(102, 99, 0);
}

.from_askSupport textarea {
  padding: 10px;
  width: 100% !important;
  background: #f6f7fb;
  border-radius: 5px;
  border: 1px solid rgb(199, 199, 199);
  /* height: calc(100% - 30px) !important; */
}

.from_askSupport .right_wing {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.from_askSupport .admin_notes {
  border-radius: 15px;
  background: #f6f7fb;
  padding: 15px;
  flex-grow: 3;

  display: flex;
  flex-direction: column;
}

.from_askSupport .notes {
  padding: 7px;
  border: 1px dotted #8e2de2;
  border-radius: 3px;
}

.from_askSupport .update_div {
  padding: 15px;
  border-radius: 15px;
  background: #f6f7fb;

  display: flex;
  gap: 10px;
}

.from_askSupport .update_div input {
  width: 100%;
  background: #f6f7fb;
  border: none;
  outline: none;
}

.from_askSupport .update_div input:focus {
  background: #f6f7fb !important;
}

.from_askSupport .update-btn {
  margin-left: auto;
  min-width: 80px;
  width: 80px;
  background-color: var(--colorDash);
}

.from_askSupport .radio_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  min-width: 30px;
  width: 30px;
  height: 30px;
}

.from_askSupport .radio_btn img {
  width: 100%;
  height: 100%;
}



.from_askSupport .bottom_line {
  position: relative;
  margin: 50px 0px;
  border-bottom: 1px solid #e7cbffa7;
  display: flex;
  justify-content: space-around;
}

.from_askSupport .end_session {
  margin-bottom: -5px;

  /* position: absolute; */
  /* top: -7px; */
  /* left: 50%;
  transform: translateX(-50%); */
}

.from_askSupport .end_session p {
  width: fit-content;
  background-color: white;
  color: #e7cbff;
  text-align: center;
  font-size: 7px;
  padding: 0px 7px;
}