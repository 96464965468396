.sign_up_korobka2 {
  position: fixed;
  background-image: url(../pages/HPforPublic/HPimages/BG_pic_DT.avif);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  top: 0;
  left: 0;
  /* margin: auto; */
  width: 100%;
  /* height: 800px !important; */
  height: 100vh;
  z-index: -2;
}

.sign_up {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 30000;
  background-color: rgba(0, 0, 0, 0.637);
  display: flex;
}

.sign_up .sign_up_subcontainer {
  position: relative;
  margin: auto;
  background: linear-gradient(114.95deg,
      rgba(235, 0, 255, 0.5) 0%,
      rgba(0, 71, 255, 0) 34.35%),
    linear-gradient(180deg, #004b5b 0%, #ffa7a7 100%),
    linear-gradient(244.35deg, #ffb26a 0%, #3676b1 50.58%, #00a3ff 100%),
    linear-gradient(244.35deg, #ffffff 0%, #004a74 49.48%, #ff0000 100%),
    linear-gradient(307.27deg, #1dac92 0.37%, #2800c6 100%),
    radial-gradient(100% 140% at 100% 0%,
      #eaff6b 0%,
      #006c7a 57.29%,
      #2200aa 100%);
  background-blend-mode: hard-light, overlay, overlay, overlay, difference,
    difference, normal;

  border-radius: 12px;
  width: 650px;
  padding-left: 0px;
  z-index: 100000;
}

.sign_up main {
  margin: auto;
  width: 100%;
  padding: 30px 50px;
}

.sign_up .sign_up_LF {
  padding: 20px;
  padding-bottom: 10px;
  text-align: center;
  color: white;
  border-radius: 15px;
  font-family: Silom;
  font-size: 26px;
}


.sign_up .login-form-close {
  position: absolute;
  font-size: 28px;
  background-color: rgba(227, 216, 1, 0.368);
  padding: 3px !important;
  top: 5px;
  right: 5px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  z-index: 10;
}

.sign_up .login-form-close:hover {
  z-index: 1000000;
  color: #ff0000;
}

.sign_up .form-container {
  overflow: scroll;
}

.sign_up form {
  margin-top: 60px !important;
  font-size: 24px;
}

.sign_up .flexed {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.sign_up .flexed>div {
  width: 100%;
}

.sign_up form input {
  width: 100%;
  padding: 8px 5px;
  font-size: 14px;
  color: #e6e6ff;
  margin-bottom: 30px;
  height: 30px;
  border: none;
  border-radius: 2px;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.sign_up input[value]:not([value='']) {
  color: rgb(64, 0, 101) !important;
  font-size: 14px !important;
  background-color: rgb(255, 250, 250);
}

.sign_up .user-box {
  position: relative;
}

.sign_up label {
  position: absolute;
  font-size: 14px !important;
  border-radius: 50%;
  top: -10px;
  left: 0;
  padding: 10px 0;
  color: #fff !important;
  pointer-events: none;
  transition: 0.5s;
}

.sign_up .user-box input:focus~label,
.sign_up input[value]:not([value=''])~label {
  top: -25px !important;
  font-size: 12px !important;
  color: cyan !important;
  color: rgb(200, 255, 0) !important;
}

/* Because it is date type, we css it differently  */
.sign_up .birthday input[type='date'] {
  color: transparent !important;
}

.sign_up .birthday input[type='date']:focus {
  outline: none;
  color: rgb(64, 0, 101) !important;
}

.sign_up .birthday input[value]:not([value='']) {
  color: rgb(64, 0, 101) !important;
}

.sign_up .submit-btn {
  position: relative;
  background-color: transparent;
  display: inline-block;
  padding: 18px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.7s;
  /* margin-top: 20px; */
  letter-spacing: 4px;
  width: 160px;
  z-index: 1;
}

.sign_up .submit-btn:hover {
  box-shadow: none;
  color: white;
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  z-index: 1;
}

.sign_up .submit-btn span {
  position: absolute;
  display: block;
}

.sign_up .submit-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4) !important;
  animation: run-around-anim 1s linear infinite !important;
}

@keyframes run-around-anim {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.sign_up .submit-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: run-around-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes run-around-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.sign_up .submit-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: run-around-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes run-around-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.sign_up .submit-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: run-around-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes run-around-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.sign_up .user_ism input::placeholder {
  color: transparent !important;
}

.sign_up .user_ism input:focus::placeholder {
  color: rgb(121, 121, 121) !important;
}

.sign_up .error-message {

  color: red;
  width: 100%;
  height: 16px;
  /* background-color: #03e9f4; */
  /* margin-bottom: 20px !important; */
}


#date_of_birth {
  color: transparent;
}

#date_of_birth:focus {
  color: rgb(122, 0, 124);
}

#date_of_birth[value]:not([value=""]) {
  color: rgb(0, 0, 0) !important;
}

.success-popup-contain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #035e00ef;
  z-index: 10000000;

  display: flex;
}

.success-popup {
  position: relative;
  width: 400px;
  padding: 60px 40px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 12px;
}

.success-popup>* {
  text-align: center;
}

.success-popup button {
  position: absolute;
  top: 10px;
  right: 10px;
  min-width: 60px;
  color: green;
  background-color: white;
}

.success-popup .with_border {
  margin: 20px 0px;
  border: 1px solid green;
  padding: 20px;
  border-radius: 7px;
}