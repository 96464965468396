.left-content {
  display: grid;
  grid-template-rows: 50% 50%;
  background: #f6f7fb;

  padding: 15px;
  padding-bottom: 5px;
  border-radius: 15px;
}

/* ACTIVITIES */

.activities h1 {
  margin: 0 0 20px;
  font-size: 1.4rem;
  font-weight: 700;
}

.activity-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 150px);
  column-gap: 10px;
}

.img-one {
  grid-area: 1 / 1 / 2 / 2;
}

.img-two {
  grid-area: 2 / 1 / 3 / 2;
}

.img-three {
  display: block;
  grid-area: 1 / 2 / 3 / 4;
}

.img-four {
  grid-area: 1 / 4 / 2 / 5;
}

.img-five {
  grid-area: 1 / 5 / 2 / 6;
}

.img-six {
  display: block;
  grid-area: 2 / 4 / 3 / 6;
}

.image-container {
  position: relative;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
  border-radius: 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
      transparent,
      transparent,
      rgba(3, 3, 185, 0.5));
  border-radius: 10px;
  transition: all 0.6s linear;
}

.image-container:hover .overlay {
  opacity: 0;
}

.overlay h3 {
  margin-bottom: 8px;
  margin-right: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
}


@media (max-width: 1500px) {
  .content {
    grid-template-columns: 70% 30%;
  }
}

@media (max-width: 1310px) {
  main {
    grid-template-columns: 8% 92%;
    /* margin: 30px; */
  }

  .content {
    grid-template-columns: 65% 35%;
  }
}

/* @media (max-width: 1150px) {
  .content {
    grid-template-columns: 60% 40%;
  }

  .left-content {
    grid-template-rows: 45% 55%;

  }

  .img-one {
    grid-area: 1 / 1 / 2 / 2;
  }

  .img-two {
    grid-area: 2 / 1 / 3 / 2;
  }

  .img-three {
    display: none;
  }

  .img-four {
    grid-area: 1 / 2 / 2 / 3;
  }

  .img-five {
    grid-area: 1 / 3 / 2 / 4;
  }

  .img-six {
    grid-area: 2 / 2 / 3 / 4;
  }

  .left-content {
    grid-template-rows: 50% 50%;
  }
} */

/* @media (max-width: 1050px) {
  .right-content {
    grid-template-rows: 5% 19% 36% 40%;
  }
}

@media (max-width: 910px) {
  main {
    grid-template-columns: 10% 90%;
  }

  .content {
    grid-template-columns: 55% 45%;
  }

  .left-content {
    grid-template-rows: 45% 55%;
  }

  .activity-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 150px);
  }

  .img-one {
    grid-area: 1 / 1 / 2 / 2;
  }

  .img-two {
    grid-area: 2 / 1 / 3 / 2;
  }

  .img-three {
    display: none;
  }

  .img-four {
    grid-area: 1 / 2 / 2 / 3;
  }

  .img-five {
    grid-area: 2 / 2 / 3 / 3;
  }

  .img-six {
    display: none;
  }
}

@media (max-width: 800px) {
  .content {
    grid-template-columns: 52% 48%;
  }
}

@media (max-width: 700px) {
  main {
    grid-template-columns: 15% 85%;
  }

  .content {
    grid-template-columns: 100%;
    grid-template-rows: 45% 55%;
    grid-template-areas:
      "rightContent"
      "leftContent";
  }

  .left-content {
    grid-area: leftContent;
  }


  .left-content {
    grid-template-rows: 45% 55%;
    padding: 10px 20px 10px;
  }
}



@media screen and (max-width: 1024px) {
  main {
    grid-template-columns: 20% 80%;
  }


  .left-content {
    grid-template-rows: 45% 55%;
  }

  .activity-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 150px);
    column-gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  main {
    grid-template-columns: 25% 75%;
  }


  .left-content {
    grid-template-rows: 45% 55%;
  }

  .activity-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 150px);
    column-gap: 10px;
  }
}

@media screen and (max-width: 576px) {
  main {
    grid-template-columns: 100%;
  }

  .left-content {
    grid-template-rows: 1fr 1fr;
  }

  .activity-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 150px);
    column-gap: 10px;
  }
} */