.lander_session_HP {
  position: relative;
  background-color: aqua;
  width: 100%;
}

.main_DT_HP {
  display: none;
}

.black_top_over_landerSession {
  position: fixed;
  background-image: url(../HPimages/BG_pic_DT.avif);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin: auto;
  width: 100%;
  height: 800px;
  height: 100vh;
}

.landing_part_HP {
  position: relative;
  margin: auto;
  width: 100%;
  height: 800px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.parda0_HP {
  position: fixed;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  top: 0px;
}

.ramka_HP {
  position: relative;
  margin: auto;
  border: 16px solid rgba(208, 0, 255, 0.549);
  width: 800px;
  height: 420px;
}

.parda_HP {
  position: absolute;
  background-color: black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0px;
}


.lander_flex_container_HP {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.the_words_HP {
  position: relative;
  /* margin: auto; */
  /* margin-top: 0px; */
  /* margin-left: 0px; */
  /* width: 130px; */
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
}

.the_words_HP_subcontainer {
  position: relative;
  margin-top: 20px;
  width: 370px;
  height: 170px;

  background-image: url(../HPimages/forLogo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: contain;
  /* z-index: 1000; */
}

.the_words_HP p {
  color: white;
  font-size: 50px;
  text-transform: uppercase;
  font-family: 'Oswald-Light', sans-serif;
  font-weight: 300;
}

.the_words_HP span {
  color: var(--topBarFontColor);
  font-family: inherit;
}

.firstWord_HP {
  position: absolute;
  top: 5px;
  left: 10px;
}

/* .vertical_line_HP {
  position: absolute;
  top: -186px;
  left: 200px;
  height: 200px;
  border-left: 0.051px solid rgb(255, 225, 0);
} */

.secondWord_HP {
  position: absolute;
  top: 85px;
  right: 5px;
}

/* .horizontal_line_HP {
  position: absolute;
  border-top: 0.051px solid rgb(255, 0, 0);
  width: 100%;

  margin-top: 0px;
  top: -90px;
  left: 40px;
  width: 370px;
} */

/* .circle_HP {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgb(125, 125, 0);
  background-image: radial-gradient(white 20%, yellow 30%, rgba(148, 146, 146, 0.681) 40%);
  border-radius: 50%;
  width: 20px;
  height: 25px;
  rotate: 1 1 0 45deg;
  z-index: 3;
} */

.main_MB_HP {
  display: none;
  position: absolute;
  padding-left: 10px;
  top: 300px;
  width: 100%;
}

.main_MB_HP>div {
  position: relative;
  margin: auto;

  color: white;
  z-index: 2;
  font-family: 'Oswald-Light', sans-serif;
  font-weight: 300;
  letter-spacing: 1.12px;
  width: 80%;
}

.main_DT_HP {
  display: block;
  /* position: absolute;
  top: 58px;
  left: 40px; */
  width: 400px;
  letter-spacing: 1.12px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.main_DT_HP p {
  font-size: 20px;
  line-height: 28px;
}

.kitab_lamp_HP_container {
  margin-top: 40px;
  margin-right: 40px;
  width: 30%;
}

.kitab_lamp_HP {
  margin: auto;
  height: 300px;

  background-image: url(../HPimages/kitob.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.plussign_and_ODP_container_HP {
  position: absolute;
  width: 100%;
  bottom: 5px;
  z-index: 3;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.plussign_HP,
.ODP_sign_HP {

  border: 4px solid var(--topBarFontColor);
  color: rgb(255, 255, 194);
  z-index: 3;
  font-family: 'Oswald-Light', sans-serif;
  font-weight: 400;
  height: 50px;
  border-radius: 2px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: fit-content;

  display: flex;
}

.ODP_sign_HP {
  min-width: fit-content;
  /* margin-right: 20px; */
}

.plussign_HP p,
.ODP_sign_HP p {

  font-family: 'El Messiri', sans-serif;
  font-size: 18px;
  text-align: center;
  color: var(--topBarFontColor);
  letter-spacing: 1.2px;
  /* text-transform: uppercase; */
}

.plussign_HP {
  display: block;
  border: 2px solid rgba(191, 163, 3, 0.73);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 18px;
  margin-top: 3px;
}

@media only screen and (max-width: 970px) {
  .landing_part_HP {
    /* height: 600px; */
  }

  .ramka_HP {
    border: 12px solid rgba(255, 200, 0, 0.549);

    width: 600px;
    height: 320px;
  }

  .the_words_HP {
    /* margin-top: 25px; */
    padding: 20px;
  }

  .the_words_HP p {
    font-size: 46px;
  }

  .the_words_HP_subcontainer {
    margin-top: 0px;
    width: 340px;
  }

  .the_words_HP .firstWord_HP {
    /* top: 10px; */
  }

  .the_words_HP .secondWord_HP {
    /* margin-top: 0px; */
    /* top: 65px; */
    /* left: 200px; */
  }

  /* .the_words_HP .vertical_line_HP {
    top: 0px;
    left: 165px;
    height: 140px;
  }

  .the_words_HP .horizontal_line_HP {
    top: 67px;
    width: 315px;
  }

  .the_words_HP .circle_HP {
    top: 48%;
  } */

  .main_DT_HP {
    /* top: 170px;
    left: 43px; */

    width: 320px;
  }

  .main_DT_HP p {
    font-size: 16px;

  }


  .kitab_lamp_HP_container {
    position: relative;
    margin: auto;
    margin-left: auto;
    margin-right: 20px;

    width: 160px;
    height: 225px;
  }

  .kitab_lamp_HP {
    margin-left: auto;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 751px) {

  .landing_part_HP {
    position: relative;
  }

  .ramka_HP {
    border: 10px solid rgba(255, 200, 0, 0.549);
    width: 500px;
    height: 280px;
  }

  .parda_HP {
    opacity: 0.5;
  }

  .lander_flex_container_HP {
    position: relative;
  }

  .the_words_HP {
    width: 55%;
    margin-left: 0px;
    flex-direction: column;
  }

  .the_words_HP p {
    font-size: 30px;
  }

  .the_words_HP .the_words_HP_subcontainer {
    top: 0px;
    margin: auto;
    margin-left: 0px;
    width: 250px;
    height: 120px;
  }

  .the_words_HP .firstWord_HP {
    /* top: 0px;
    left: 0px; */
  }

  .the_words_HP .secondWord_HP {
    top: 67px;
    right: 0px;
  }

  .the_words_HP .vertical_line_HP {
    position: absolute;
    top: 16px;
    left: 88px;
    height: 100px;
    border-left: 0.051px solid rgb(255, 225, 0);
  }

  .the_words_HP .horizontal_line_HP {
    top: 61px;
    left: 0px;
    width: 205px;
  }

  .the_words_HP .circle_HP {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: rgb(125, 125, 0);
    background-image: radial-gradient(white 20%, yellow 30%, rgba(148, 146, 146, 0.681) 40%);
    border-radius: 50%;
    width: 20px;
    height: 25px;
    rotate: 1 1 0 45deg;

    z-index: 3;
  }

  .main_DT_HP {
    width: 270px;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .main_DT_HP p {
    font-size: 14px;
  }

  .kitab_lamp_HP_container {
    position: relative;
    margin: auto;
    /* right: 10px; */

    width: 140px;
    height: 205px;
  }

  .kitab_lamp_HP {
    width: 100%;
    height: 100%;
  }
}



@media only screen and (max-width: 601px) {


  .landing_part_HP {
    position: relative;
    margin: auto;
    background-image: url(../HPimages/BG_pix_MB.jpg);
    background-position: bottom left;
    background-size: cover;
  }

  .ramka_HP {
    position: relative;
    width: 80%;
    border: 10px solid rgba(103, 191, 3, 0.73);
    border: 10px solid rgba(188, 191, 3, 0.73);

  }

  .the_words_HP span {
    color: yellow;
  }

  .main_DT_HP {
    width: 220px;
  }

  .main_DT_HP p {
    line-height: 22px;
  }

  .plussign_HP {
    border: 3px solid yellow;
  }

  .plussign_HP p,
  .ODP_sign_HP p {
    color: yellow;
  }

  .parda_HP {
    opacity: 0.6;
  }
}


@media only screen and (max-width: 584px) {

  .the_words_HP {
    padding-left: 10px;
  }

  .the_words_HP .the_words_HP_subcontainer {
    margin-left: 0px;
    width: 220px;
    height: 120px;
  }


}




@media only screen and (max-width: 528px) {
  .landing_part_HP {
    height: 100vh;
    background-position: bottom center;
  }

  .ramka_HP {
    width: 90%;
  }

  .firstWord_HP {
    left: 4px;
  }

  .main_DT_HP {
    top: 160px;
    width: 240px;
  }

  .ODP_sign_HP {
    min-width: 42px;
    height: 42px;
    padding-right: 5px;
  }

  .ODP_sign_HP p {
    font-size: 20px;
    font-family: 'Arial Narrow Bold', sans-serif;
    color: yellow;
  }
}

@media only screen and (max-width: 514px) {
  .kitab_lamp_HP_container {
    display: none;
  }

  .ramka_HP {
    width: 330px;
  }

  .main_DT_HP {
    top: 150px;
    width: 210px;
  }

  .the_words_HP_subcontainer {
    margin-left: 0px;
  }

  .the_words_HP .the_words_HP_subcontainer {

    width: 250px;

  }

}

@media only screen and (max-width: 430px) {


  .the_words_HP {
    margin-left: 7px;
  }

  .the_words_HP_subcontainer {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 412px) {

  .ODP_sign_HP p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 363px) {
  .ramka_HP {
    width: 300px;
  }

  .the_words_HP {
    margin-left: 0px;
    padding: 10px;
  }

  .main_DT_HP {
    top: 160px;
    width: 250px;
  }

  .ODP_sign_HP p {
    font-size: 16px;
  }
}