.invitation_case_contain {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: #000000b6;
  opacity: 0;

  z-index: 10;
  display: flex;

  animation: blossom 0.3s ease-in-out forwards;
}

.invitation_case {
  position: relative;
  margin: auto;
  color: white;
  /* font-size: 20px; */
  background-color: rgb(255, 252, 222);

  background: linear-gradient(114.95deg,
      rgba(235, 0, 255, 0.5) 0%,
      rgba(0, 71, 255, 0) 34.35%),
    linear-gradient(180deg, #004b5b 0%, #ffa7a7 100%),
    linear-gradient(244.35deg, #ffb26a 0%, #3676b1 50.58%, #00a3ff 100%),
    linear-gradient(244.35deg, #ffffff 0%, #004a74 49.48%, #ff0000 100%),
    linear-gradient(307.27deg, #1dac92 0.37%, #2800c6 100%),
    radial-gradient(100% 140% at 100% 0%,
      #eaff6b 0%,
      #006c7a 57.29%,
      #2200aa 100%);
  background-blend-mode: hard-light, overlay, overlay, overlay, difference,
    difference, normal;

  opacity: 0;
  width: 430px;
  height: fit-content;
  border-radius: 20px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); */

  display: flex !important;
  animation: blossom 0.3s ease-in-out forwards 0.2s;
}

@keyframes blossom {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.invitation_case form {
  width: 100%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.invitation_case h2,
.invitation_case h2 span {
  font-family: 'Oswald';
  font-size: 38px;
  letter-spacing: 0.7px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
}

.invitation_case h2 span {
  color: yellow;
}



.invitation_case .flex_it {
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 4px;
}

.invitation_case .flex_it label {
  padding: 10px 2px;
  width: fit-content;
  white-space: nowrap;
  font-size: 16px;
}

.invitation_case .flex_it select {
  width: 100%;
  padding: 0px;
}

.invitation_case select {
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 16px;
  border: 0.2px solid rgb(60, 100, 0);
}

.invitation_case input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  color: white;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 20px;
  padding-left: 10px;
  font-size: 16px !important;
}

input:focus {
  background-color: rgb(239, 255, 254) !important;
}

.invitation_case input::placeholder,
.invitation_case textarea::placeholder {
  color: rgb(198, 198, 198);
  padding: 10px;
}

.invitation_case input:focus {
  background-color: rgb(241, 252, 255);
  color: black;
}

.invitation_case textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 12px;
}

.invitation_case input[value]:not([value='']) {
  font-size: 18px;
}

.invitation_case .submit-btn {
  height: 35px;
  border-radius: 12px;
  background-color: #3b0054;
}

.invitation_case .submit-btn:hover {
  background-color: #0aff02;
  font-weight: 600;
  color: #3b0054;
}


.invitation_case .flexed {
  display: flex;
  gap: 30px;
}

.invitation_case .error {
  color: red;
}

.invitation_case .goodNews {
  /* padding-top: 5px; */
  /* font-family: fantasy; */
  /* font-size: 16px; */
  color: rgb(0, 157, 8);
}