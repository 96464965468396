.for_click {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}


table {
  /* border-spacing: 0px; */
  width: 100%;
  border-spacing: 0px;
  max-width: 100%;
}

.admin-jsx tr {
  display: inline-block;
  padding: 5px 10px !important;
  width: 100%;
  cursor: pointer;
  /* background-color: aliceblue; */

  display: flex;
  justify-content: space-between;
  justify-content: left;
  gap: 5px;
}

tr:hover>td {
  /* background-color: rgb(246, 246, 246); */

  color: rgb(117, 3, 183) !important;
  background-color: var(--bgGray);
  background-color: rgb(244, 244, 244);

  box-shadow: 0 0 3px 0 #606060;

  transition: all 0.3s ease-in-out;
}

td {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  /* margin-right: 10px; */
  white-space: nowrap;
}

td>div {
  height: 30px;
}

td>div>p {
  padding: 2px;
}



.dashboard-names,
.dashboard-occupation,
.dashboard-searchName {
  /* min-width: 180px; */
  min-width: 180px;
  overflow: scroll !important;
}

.dashboard-names {
  width: 100%;
  max-width: fit-content;
  flex-grow: 2;
  min-width: 180px;
}

.dashboard-occupation {
  min-width: 220px;
  width: 300px;
}

.dashboard-searchName {
  color: green;
}


/* .dashboard-location {
  width: 100%;
  overflow: scroll !important;
} */

.dashboard-age {
  min-width: 130px;
}

.dashboard-securityLevel {
  min-width: 100px;
}

.dashboard-ordinals {
  min-width: 40px;
  width: 40px;
  padding: 4px 15px;

  display: flex;
}

.dashboard-ordinals>div {
  margin: auto;
}