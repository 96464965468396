#message_create_container {
  position: absolute;
  top: 0px;
  left: 0px;

  height: 100%;
  background-color: rgba(59, 59, 59, 0.809);
  border: none;
  z-index: 3;
  /* display: flex; */
}


.message_create {
  position: relative;
  padding: 10px 15px;

  margin-top: 40px;
  margin-left: 160px;
  width: 70% !important;
  background-color: white;
  border-radius: 12px;
  height: fit-content;
  max-height: 100%;
  overflow: hidden;
  overflow: scroll;

  display: flex;
  flex-direction: column;

  z-index: 1;
}

.message_create header {
  margin-top: 10px;
  width: 100%;
  color: green;
  font-size: 16px;

  display: flex;
  justify-content: space-between;
}

.message_create .close_new_message {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: auto;
  margin-left: 0px;
  background-color: transparent;
  color: rgb(192, 192, 192);
  background-color: rgb(249, 249, 249);
  min-width: 40px;
  width: 40px;
  height: 40px;

  padding: 6px 2px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50%;
  cursor: pointer;
}

.message_create .close_new_message:hover {
  background-color: rgb(185, 185, 185);
  color: rgb(0, 0, 0);
  box-shadow: none;
}

.message_create .titul {
  /* text-align: center; */
  /* text-transform: uppercase; */
  margin: 20px auto;
  font-size: 18px;
  color: rgb(59, 59, 59);
}

.message_create .titul h4 {
  font-size: 22px;
  margin-bottom: 5px;

}

.message_create main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.message_create .recipient {
  width: 100%;
}

.message_create .container {
  position: relative;

  border: 1px solid rgb(232, 232, 232);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
}

.message_create textarea {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  /* padding: 20px; */
}

.message_create textarea:focus {
  background-color: initial !important;
}

.message_create textarea::placeholder {
  color: rgb(213, 213, 213);
}

.for_success_and_errors,
.goodNews {
  margin: auto;
  margin-left: 0px;
  white-space: wrap;
  color: red;
}

.goodNews {
  color: green;
}

.message_create .send_btn {
  margin: auto;
  flex-grow: 0;
  margin-left: 20px;
  border-radius: 10px !important;
  background-color: rgb(73, 73, 73) !important;
  padding: 12px 35px !important;
  min-width: 90px !important;
  color: white !important;
  height: 40px;
  cursor: pointer;
}

.message_create .send_btn:hover {
  background-color: rgb(0, 214, 36) !important;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}