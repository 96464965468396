/* Visually this knopka is seen on HP-navigation barr */
.header_login_btn {
  color: var(--topBarFontColor);
  padding: 6px 6px !important;
  min-width: 90px;
  min-height: 40px;
  margin-right: 5px;
  background-color: transparent;
  cursor: pointer;
}

.header_login_btn:hover {
  color: var(--topBarFontColor);
  background-color: rgba(234, 242, 234, 0);
  color: rgba(204, 255, 0, 0.871);
  border-radius: 22px;
  box-shadow: none;
}

.header_login_btn p {
  font-size: 18px;
  font-family: 'El Messiri', sans-serif !important;
  letter-spacing: 1.2px;
}


/* For the compoment box elements: */


.login_form_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;



}

.parda01_HP {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.1;
  z-index: 10;
}

.login-box-component {
  position: relative;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);

  background: linear-gradient(114.95deg, rgba(235, 0, 255, 0.5) 0%, rgba(0, 71, 255, 0) 34.35%), linear-gradient(180deg, #004B5B 0%, #FFA7A7 100%), linear-gradient(244.35deg, #FFB26A 0%, #3676B1 50.58%, #00A3FF 100%), linear-gradient(244.35deg, #FFFFFF 0%, #004A74 49.48%, #FF0000 100%), linear-gradient(307.27deg, #1DAC92 0.37%, #2800C6 100%), radial-gradient(100% 140% at 100% 0%, #EAFF6B 0%, #006C7A 57.29%, #2200AA 100%);
  background-blend-mode: hard-light, overlay, overlay, overlay, difference, difference, normal;

  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
  z-index: 10000;

  display: flex;
  flex-direction: column;
}



.login-box-component .login-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 22px;
  background-color: rgb(231, 248, 39);
  padding: 6px 6px !important;
  top: 5px;
  right: 5px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  z-index: 1000000;
}

.login-box-component .login-close:hover {
  color: red;
  background-color: rgb(255, 236, 236);
}

.login-box-component .simple_form_to_login {
  display: flex;
  flex-direction: column;
}

.login-box-component .not_registered {
  color: rgb(179, 18, 243);
  display: flex;
}

.login-box-component .not_registered button {
  color: rgb(218, 125, 255);
  font-family: fantasy;
  font-weight: 600;
  background-color: transparent;
  padding: 2px 0px;
  min-width: 50px;
  cursor: pointer;
}

.login-box-component h2 {
  margin: 0 0 60px;
  margin-top: 30px;
  font-weight: 300;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box-component .user-box {
  position: relative;
}

.login-box-component .user-box input {
  width: 100%;
  padding: 8px 5px;
  font-size: 16px;
  color: #6a67f9;
  margin-bottom: 50px;
  height: 30px;
  border: none;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box-component .user-box label {
  position: absolute;
  border-radius: 50%;
  top: -10px;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

/* Strange. If I delete {required} from the password field, it behaves strangely */
.login-box-component .user-box input:focus~label,
.login-box-component input:valid~label {
  top: -26px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.last_box input {
  margin-bottom: 10px !important;
}

.login-box-component input[value]:not([value=""])~label {
  top: -26px;
}

.authErrorMessage {
  color: rgb(255, 103, 103);
}

.login-box-component .submit-btn {
  background-color: transparent;
  font-size: 22px;

  position: relative;
  display: inline-block;
  padding: 17px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .7s;
  margin-top: 30px;
  letter-spacing: 4px;
  width: 160px;

  cursor: pointer;
  z-index: 1;
}

.login-box-component .submit-btn:hover {

  box-shadow: none;
  color: white;
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;

  z-index: 1;
}

.login-box-component .submit-btn span {
  position: absolute;
  display: block;
}


.login-box-component .submit-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: run-around-anim 1s linear infinite;
}

@keyframes run-around-anim {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.login-box-component .submit-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: run-around-anim2 1s linear infinite;
  animation-delay: .2s
}

@keyframes run-around-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.login-box-component .submit-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: run-around-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes run-around-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.login-box-component .submit-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: run-around-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes run-around-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}


@media only screen and (max-width: 600px) {
  .login-box-component {
    width: 300px;
  }
}

.trust_this_device {
  margin-left: 5px;
  margin-top: 20px;
}

.trust_this_device input:valid~label {
  margin-left: 10px;
  color: #EAFF6B;
}