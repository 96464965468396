.review_create_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(106, 133, 255, 0.874);
  border: none;
  z-index: 3;

  display: flex;
  opacity: 0;
  animation: blossom 0.3s ease-in-out forwards;
}


.review_create {
  position: relative;
  padding: 10px 15px;

  margin: auto;

  width: 500px !important;
  background-color: white;
  border-radius: 12px;
  height: fit-content;
  max-height: 100%;
  overflow: hidden;
  overflow: scroll;

  display: flex;
  flex-direction: column;

  z-index: 1;
  opacity: 0;
  animation: blossom 0.3s ease-in-out forwards 0.2s;
}

@keyframes blossom {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.review_create header {
  margin-top: 10px;
  width: 100%;
  color: green;
  font-size: 16px;

  display: flex;
  justify-content: space-between;
}

.review_create .close_new_review {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: auto;
  margin-left: 0px;
  background-color: transparent;
  color: rgb(192, 192, 192);
  background-color: rgb(249, 249, 249);
  min-width: 40px;
  width: 40px;
  height: 40px;

  padding: 6px 2px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50%;
  cursor: pointer;
}

.review_create .close_new_review:hover {
  background-color: rgb(0, 98, 255);
  color: rgb(255, 255, 255);
  box-shadow: none;
}

.review_create .titul {
  margin: 20px auto;
  font-size: 18px;
  color: rgb(59, 59, 59);
}

.review_create .titul h4 {
  font-size: 24px;
  font-weight: 500;
  color: rgb(0, 98, 255);
  margin-bottom: 10px;
}

.review_create .titul span {
  color: rgb(0, 98, 255);
}

.review_create main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.review_create .recipient {
  width: 100%;
}

.review_create .container {
  position: relative;

  border: 1px solid rgb(232, 232, 232);
  border-radius: 10px;

  padding: 20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
}

.review_create textarea {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  /* padding: 20px; */
}

.review_create textarea:focus {
  background-color: initial !important;
}

.review_create textarea::placeholder {
  font-family: fantasy;
  color: rgb(0, 98, 255);
  font-size: large;
}

.for_success_and_errors,
.goodNews {
  margin: auto;
  margin-left: 0px;
  white-space: wrap;
  color: red;
}

.goodNews {
  color: green;
}

.review_create .post_review_btn {
  margin: auto;
  flex-grow: 0;
  margin-left: 20px;
  border-radius: 10px !important;
  background-color: rgb(66, 138, 255);
  padding: 12px 35px !important;
  min-width: 90px !important;
  color: white !important;
  height: 40px;
  cursor: pointer;
}

.review_create .post_review_btn:hover {
  background-color: rgb(0, 98, 255);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}