/* General styles  */
.from_contactUs .flex_it {
  display: flex;
}

.from_contactUs .space_between {
  display: flex;
  justify-content: space-between;
}

.from_contactUs .golden_heading {
  font-family: 'Silom';
  color: var(--colorDash);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}


/* main_parts_of_code  */
.from_contactUs {
  margin-top: 12px;
  padding: 0px 10px;
}

.from_contactUs header {
  width: fit-content;
  padding: 10px;
  border-radius: 7px;

  display: flex;
  gap: 15px;
}

.from_contactUs header p {
  font-family: 'Silom';
  color: var(--colorDash);

  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.from_contactUs .mapping_part {
  position: relative;
  margin: 20px 0px;
  margin-bottom: 50px;
  padding: 10px;
  /* border: 1px solid gray; */
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.from_contactUs .left_wing {
  width: 680px;

  background: #f6f7fb;
  background: #e8ecff;
  border-radius: 15px;
  padding: 15px;
  /* height: 320px; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.from_contactUs .left_wing p {
  font-size: 10px;
}

.from_contactUs .upper_part {
  /* height: 100%; */
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.from_contactUs .profile_picture {
  position: relative;
  min-width: 100px;
  width: 100px;
  height: 100px;
  border-radius: 17px;
  overflow: hidden;
}

.from_contactUs .profile_picture img {

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.from_contactUs .reviews {
  position: absolute;
  left: 0px;
  bottom: 7px;
  min-width: 50px;
  width: 50px;
  padding: 2px;
  font-size: 8px;
  background-color: rgb(56, 112, 255);
  color: white;
  border-radius: 0px 2px 2px 0px;
}

.from_contactUs .gegevens {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.from_contactUs .user_nick {
  font-family: 'Silom';
  color: var(--colorDash);
  font-weight: 500;
  font-size: 14px !important;
  white-space: nowrap;
}

.from_contactUs .searchName {
  width: 100%;
  color: green;
  font-size: 14px;
  font-weight: 300;
}

.from_contactUs .lower_part {
  position: relative;
  height: 100%;
  overflow: scroll;
}

.from_contactUs .middle_part {
  border-radius: 15px;
  background: #f6f7fb;
  padding: 15px;
  padding-bottom: 10px;
  width: 100%;
  flex-grow: 4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.from_contactUs .his_message {
  flex-grow: 3;
}


.from_contactUs .message_text {
  color: #5900a6;
}

.from_contactUs textarea {
  padding: 10px;
  width: 100% !important;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid rgb(199, 199, 199);
  /* border: none; */
  height: calc(100% - 30px) !important;
}

.from_contactUs .right_wing {
  width: 100%;
  border-radius: 15px;
  background: #f6f7fb;
  padding: 15px;
  padding-bottom: 15px;
  flex-grow: 3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}


.from_contactUs .notes {
  padding: 7px;
  border: 1px dotted #8e2de2;
  border-radius: 3px;
  font-weight: 100;
  font-size: 12px;
}

.from_contactUs .update_div {
  /* padding: 15px; */
  border-radius: 3px;
  background: #f6f7fb;
  display: flex;
}

.from_contactUs .update_div input {
  width: 100%;
  background: #f6f7fb;
  padding-left: 10px;
  outline: none;
  border: 1px solid #6873a7;
  border-right: none;
  border-radius: 3px 0 0 3px;
}

.from_contactUs .update_div input:focus {
  background: #f6f7fb !important;
}

.from_contactUs .update-btn {
  margin-left: auto;
  min-width: 80px;
  width: 80px;
  background-color: var(--colorDash);
  background-color: #5900a6;
  background-color: #6873a7;
  border-radius: 0 3px 3px 0;
}

.from_contactUs .update-btn:hover {
  background-color: #374486;
}

.from_contactUs .btns {
  position: absolute;
  top: 14px;
  right: 20px;
  display: flex;
  gap: 5px;
}

.from_contactUs .delete_btn,
.from_contactUs .radio_btn {
  padding: 5px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.from_contactUs .radio_btn {
  padding: 0px;
}

.from_contactUs .delete_btn:hover {
  background-color: #ecd6ff;
}

.from_contactUs .radio_btn:hover {
  background-color: #ecd6ff;
}

.from_contactUs .delete_btn img,
.from_contactUs .radio_btn img {
  width: 100%;
  height: 100%;
}

.from_contactUs .bottom_line {
  position: relative;
  margin: 50px 0px;
  border-bottom: 1px solid #e7cbffa7;
  display: flex;
  justify-content: space-around;
}

.from_contactUs .end_session {
  margin-bottom: -5px;
}

.from_contactUs .end_session p {
  width: fit-content;
  background-color: white;
  color: #e7cbff;
  text-align: center;
  font-size: 7px;
  padding: 0px 7px;
}