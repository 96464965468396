.nav-container {
  padding: 15px 10px;
  padding-right: 0px;
  background-color: var(--bgGray);
  min-width: 160px;
  border-radius: 16px 0 0 16px;

  overflow: hidden;
}

.nav {

  display: flex;
  flex-direction: column;
  gap: 0px;
}

.logo_name {
  padding: 2px;
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  gap: 10px;
}



.logo_name p {
  font-family: 'Silom';
  white-space: nowrap;
  font-size: 18px;
  width: 100px;
  color: rgb(96, 199, 96);
  color: white;
}




.nav a {
  font-family: var(--fontDash);
  font-size: 14px;
  color: gray;
  color: white;
  /* border-right: 10px solid rgb(12, 37, 200); */
  border-right: 10px solid var(--darkGray);
  padding: 12px 20px;
  transition: all 0.6s ease-in-out;
}

.nav>div {
  /* border-right: 10px solid var(--darkGray); */
}

.nav a:hover {
  cursor: pointer;
  /* background-color: rgb(214, 214, 214); */

  /* border-left: 8px solid var(--bgGray); */
  /* border-right: 10px solid rgba(255, 208, 0, 0.758);
  border-right: 10px solid rgb(255, 255, 255); */
  /* border-left: 5px solid rgb(255, 208, 0); */
  border-radius: 26px 0px 0px 26px;
  letter-spacing: 1.2px;
}

.nav .active {
  letter-spacing: 1.102px;
  color: rgb(54, 54, 54);
  background-color: rgb(255, 255, 255);
  border-left: 8px solid var(--bgGray);
  border-right: 10px solid rgb(255, 255, 255);
  border-radius: 26px 0px 0px 26px;
  transition: all 0.6s ease-in-out;
}


.nav .active:hover {
  color: rgb(0, 0, 0);
  /* background-color: white;
  border-right: 10px solid white; */
}

.spacerGray {
  height: -10px;
  padding: 10px;
  border-right: 10px solid var(--darkGray);
}

.spacerGrayBorder {
  border-top: 10px solid var(--darkGray);
}