.profile-case-container {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.826);
  width: 100%;
  height: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
}

.profile-case-container>main {
  position: relative;
  margin: auto;
  width: 950px;
}

.profile-case .congrats {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #6600afdd;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  z-index: 10;

  display: flex;
  animation: fade 2s ease-in-out forwards 2s;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.profile-case .congr_sub {
  margin: auto;
  animation: enlargeAndFade 4s ease-in-out forwards 2s;
}

@keyframes enlargeAndFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.profile-case .congrats h4 {
  text-align: center;
  letter-spacing: 1.7px;
  font-family: fantasy;
  /* color: rgb(167, 255, 167); */
  font-size: 28px;
  margin-bottom: 10px;

}

.profile-case .congrats p {
  text-align: center;
  font-size: 22px;
}

.profile-case .close_congrats {
  position: absolute;
  min-width: 40px;
  height: 40px;
  top: 10px;
  right: 15px;
  font-size: 18px;
  color: white;
  border-radius: 50%;
  background-color: transparent;
}

.profile-case .close_congrats:hover {
  background-color: transparent;
  box-shadow: none;
  font-size: 30px;
}

.profile-case .flex_it {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.profile-case .flex_it_also {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.profile-case .flexing_it {
  display: flex;
  justify-content: left;
  gap: 0px;
}



.profile-case .flexing_this_too {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.profile-activity,
.profile-level {
  width: 100% !important;
}

.Status_of_activity label,
.profile-level label {
  color: gray;
  font-size: 13px;
}

.profile-case {
  position: relative;
  right: 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.773);
  border-radius: 16px;
  margin: auto;
  width: 950px;
  padding: 10px;
  /* min-height: 580px; */
  z-index: 2;
}

.all_kind_of_buttons {
  position: absolute;
  top: 10px;
  right: 15px;

  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.close_profile_case {
  min-width: 40px;
  min-height: 40px;
  color: rgb(255, 255, 255);
  background-color: rgba(153, 153, 153, 0.547);
  border-radius: 50%;
  cursor: pointer;
  z-index: 5;
}

.close_profile_case:hover {
  color: white;
  background-color: blueviolet;
}

.remove_comment {
  position: absolute;
  right: 5px;
  bottom: 5px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 20%;
  padding: 2px;
  display: flex;
}

.profile-case .remove_comment:hover {
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.773);
}

.profile-case .remove_comment img {
  padding: 2px;
  width: 15px;
  height: 15px;
}

.profile-case .add_comment {
  position: absolute;
  top: 12px;
  right: 15px;
  min-width: 20px;
  min-height: 10px;
  height: 20px;
  padding: 1px 10px;
  background-color: rgb(244, 233, 255);
  color: rgb(79, 0, 128);
  border: 1px solid rgb(79, 0, 128);
  cursor: pointer;
  z-index: 2;
}

.profile-case .add_comment:hover {
  color: white;
  background-color: rgb(195, 98, 255);
  border: 1px solid rgb(195, 98, 255);
}

.profile-case-subcontainer {
  position: relative;
  padding: 10px;
  min-height: 300px;
  max-height: fit-content;

  display: flex;
  gap: 12px;
}

.profile-case .another_container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.profile-case .profile_left_panel {
  width: 570px;
  padding: 15px;
  background: #f6f7fb;
  border-radius: 15px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.profile-case .left_wing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1px;
}

.profile-case .profile_right_panel {
  width: 100%;
  overflow: scroll;
}

.profile-case .profile_pix_and_name {
  display: flex;
  gap: 20px;
}

.profile-case .profile_pix {
  position: relative;
  min-width: 130px;
  height: 130px;
}

.profile-case .profile_pix img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.profile-case .profile_info {
  width: 440px;
  background: #f6f7fb;
  border-radius: 15px;
  padding: 15px;
  height: 320px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1px;
}

.profile-case .profile_info .upper_part {
  height: 100%;
}

.profile-case .profile_info .lower_part {
  position: relative;
  height: 100%;
  overflow: scroll;
}

.profile-case .profile_info span {
  color: gray;
}

.profile-case .profile_name {
  display: flex;
}

.profile-case .profile_name p {
  color: var(--colorDash);
  font-weight: 500;
  font-size: 16px;
}

.profile-case .nick p {
  font-family: 'Silom';
  margin-bottom: 10px;
}

.profile-case .other_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
}

.profile-case .list {
  display: inline-block;
  font-size: 14px;
  width: 95px;
  font-weight: 300;
}

.profile-case .searchN {
  margin: 7px 0px;
}

.profile-case .profile_name .searchName {
  display: inline-block;
  padding-top: 2px;
  width: 100%;
  color: green;
  font-size: 14px;
  font-weight: 300;
}

.profile-case .profile_self_note {
  position: relative;
  height: fit-content;
  height: 100%;
  overflow: scroll;
  background-color: #f6f7fb;
}


.profile-case .second_note {
  position: relative;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
}



.profile-case .profile-role p {
  color: black;
  overflow: scroll;
}

.profile-case .profile-experience {
  /* margin: auto; */
  min-width: 200px;
  width: fit-content;

}

.profile-case .profile-activity select,
.profile-case .profile-level select {
  border: none;
}

.profile-case .profile_self_note p {
  display: inline-block;
  font-weight: 300 !important;
  font-size: 14px;
  color: black;
  /* font-weight: 300 !important; */
}

.profile-case .author {
  width: 100%;
  color: rgb(81, 0, 139) !important;
  font-size: 12px;
}


.profile-case .profile-left-panel-rows {
  /* margin: 15px; */
  padding: 10px;
  background: #f6f7fb;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.profile-case .profile_rows>div>div {
  height: 100%;
  border: 1px solid gray;
  border-radius: 6px;

  background-color: white;
  padding: 4px 7px;
}

.profile-case .profile_rows>div {
  width: 100%;
  height: 100%;
}

.profile-case .profile_right_panel {
  position: relative;
  padding: 15px;
  padding-top: 45px;
  background: #f6f7fb;
  border-radius: 15px;
}





.profile-case .profile-left-panel-rows .profile-level {
  min-width: 67px;
  width: fit-content;
}

.profile-role {
  width: 100%;
}

.profile_role_div,
.profile_experience_div {
  margin: auto !important;
  min-width: 100%;
  padding: 4px 7px !important;
}

/* .profile_role_div {
  height: fit-content !important;
} */

.profile-case .profile-left-panel-rows .profile-role>div {
  /* overflow: scroll; */
  height: fit-content !important;
}

.profile-case .add-a-role {
  min-width: 60.5px;
  width: fit-content;
  height: 30px;
  padding: 8px 15px;
  background-color: rgb(22, 210, 5);
  display: flex;
}

.profile-case .add-a-role p {
  font-size: 12px;

}

.profile-case .profile-connections,
.profile-case .profile-connections>div {
  width: 100% !important;
  white-space: nowrap;
}

.profile-case .profile-connections {
  display: flex;
  text-align: center;
  gap: 5px;
}

.profile-case .profile-connections>div {
  width: 100% !important;
  white-space: nowrap;
  background-color: yellow !important;
}

.profile-case>div {
  display: inline-block;
  margin-right: 0px;
}

.profile-case .profile_rows>div>div {
  /* height: 30px; */
}

.profile-case .profile_rows div>div>p {
  padding: 2px;
}



.add_roles {
  margin-bottom: 5px;
  padding: 6px 13px !important;

  border: 1px solid gray;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  background-color: rgb(244, 233, 255);
}

.add_roles:hover {
  color: black !important;
  background-color: rgb(223, 243, 255) !important;
}

.roles_added {
  color: red;
}

.profile-case .select_here {
  width: fit-content;
  height: 100%;
  border: 1px solid gray;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: rgb(244, 233, 255);
}

.profile-case .select_here select {
  height: 140px;
  /* padding: 10px 13px; */
  padding-top: 5px;
  border: none;
  /* color: rgb(158, 99, 252); */
  background-color: rgb(244, 233, 255);
  border-radius: 0 0 6px 6px;
}

.profile-case .select_here select:focus {
  border: none;
  outline: none;
}


.profile-case .select_here option {
  padding: 2px 13px;
  margin: 2px 0px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.profile-case .select_here option:hover {
  color: black;
  background-color: rgb(179, 1, 255);
  background-color: rgb(223, 191, 255);
}



.profile-case .select_here option:checked {
  background-color: rgb(255, 255, 255);
  color: yellow;
  color: red;
}

.profile-case .profile_location {
  margin-bottom: 0px;
  display: flex;
}

.profile-case .profile_rows {
  margin: 0px !important;

  padding: 0px !important;
  width: 100%;
  cursor: unset;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.profile-case .profile_rows:hover {
  background-color: transparent;
}

.profile-case .bio {
  margin-top: 15px;
  padding-bottom: 8px;
  color: gray;
  z-index: 1;
}

.notesByOthers {
  margin-bottom: 0px;
  /* height: 100%; */
}

.comments_by_others {
  position: absolute;
  top: 14px;
  padding-left: 7px;
  padding-bottom: 10px;
  color: gray;
  z-index: 1;
}

.notesByOthers .notes_container {
  letter-spacing: 0.45px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  color: rgb(45, 45, 45);
  height: 140px;
  /* height: 100%; */
  font-weight: 300 !important;
  font-size: 14px;
}

.notesByOthers .notes_container .timing {
  margin-top: 5px;
  color: rgb(132, 0, 255);
  font-weight: 400;
}

.notesByOthers .notes_container .timing span {
  margin-left: 10px;
  color: rgb(183, 0, 255);
  font-weight: 400;
}

.notesByOthers article {
  position: relative;
  padding: 7px;
  background-color: white;
  border: 1px dotted rgb(139, 123, 0);
  border-radius: 6px;
  overflow: scroll;
  margin-top: 5px;
}

.notesByOthers article:first-child {
  position: relative;
  margin-top: 0px;
  /* border-top: none; */
  border-radius: 6px;

}

.update_this_user {
  margin-top: auto;
  min-width: 50px;
  width: 100%;
  height: 30px;
  border-radius: 6px;
  background-color: rgb(229, 255, 244);
  color: green;
  border: 1px solid green;
  cursor: pointer;
}

.update_this_user:hover {
  background-color: green;
  color: white;
}

.for_delete {
  margin-top: auto;
  min-width: 65px !important;
  width: 65px !important;
}

.for_delete button {
  min-width: 65px;
  height: 30px;
  background-color: rgb(244, 233, 255);
  color: rgb(79, 0, 128);
  border: 1px solid rgb(79, 0, 128);
  border-radius: 6px;
  padding: 0px 0px;
  cursor: pointer;
}

.for_delete button:hover {
  color: white;
  border: 1px solid rgb(226, 43, 43);
  background-color: rgb(226, 43, 43);
}

.profile-case .message {
  text-align: center;
  border: 1px solid rgb(0, 147, 226) !important;
  border-radius: 6px;
  color: #0043afdd;
  background-color: rgb(207, 238, 255) !important;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}

.profile-case .message:hover {
  background-color: rgb(0, 147, 226) !important;
  color: white;
}

.disabled {
  /* opacity: 0.5; */
  /* You can adjust the styling based on your needs */
  pointer-events: none;
}