.sidebar_search {
  margin: auto;
  margin-left: 5px;
  width: 280px;
  width: fit-content;
  z-index: 3;
  display: flex;
}

.sidebar_search form {
  position: relative;
  margin-top: 2px;
  color: #555;
  display: flex;
  height: 25px;
  border: -1px solid currentColor;
  border-radius: 8px;
  min-width: 400px;

}


.sidebar_search input[type="search"] {
  position: absolute;
  right: 0px;
  border: none;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 5px 6px;
  padding-left: 34px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;

  outline: none;
  border: none;
  box-shadow: 0 0 3px 0 #666a6d;
  /* z-index: 1; */
}

.sidebar_search input[type="search"]::placeholder {
  color: #969696;
  /* color: #0a1971; */
  padding-left: 5px;
}

.sidebar_search input[type="search"]:focus {
  padding-left: 34px;
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
  border: none;
}

.search_unicode {
  position: absolute;
  top: 0px;
  left: 14px;
  font-size: 26px;
  color: rgb(175, 175, 175);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* .sidebar_search .typed-input {
  border: 1px solid red !important;
  background-color: var(--bgGray) !important;
  color: white !important;
  box-shadow: none !important;
  border-radius: 0px !important;
} */