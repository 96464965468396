  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400&display=swap');

  @font-face {
    font-family: "Silom";
    font-style: normal;
    font-weight: normal;
    /* src: "../node_modules/silom.ttf"; */
    src: url("../assets/silom.ttf") format('truetype');
  }

  @font-face {
    font-family: 'Oswald-Light';
    src: url('../assets/Oswald-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Oswald-Regular';
    src: url('../assets/Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Oswald-SemiBold';
    src: url('../assets/Oswald-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    /* position: fixed; */
    top: 0px;
    left: 0px;
    width: 100%;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    list-style: none;
  }

  :root {
    --fontDash: 'Silom', sans-serif;
    --fontRoboto: 'Roboto', sans-serif;
    --fontRobotoCondensed: 'Roboto Condensed', sans-serif;

    --colorDash: rgb(105, 84, 0);
    --fontColorDashDark: rgb(75, 60, 0);
    /* --bgGray: rgb(202, 202, 202); */
    --bgGray: rgb(73, 57, 113);
    /* --darkGray: rgb(121, 121, 121); */
    --darkGray: rgb(73, 57, 113);
    --topBarFontColor: rgba(255, 183, 0, 0.663);
    --ramka: width: 90%;
  }

  button {
    min-width: 100px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    background-color: green;
    color: white;
    transition: all 0.4s ease-in-out;
  }

  button:hover {
    background-color: rgb(0, 137, 0);
    /* color: rgb(78, 72, 0); */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .yellow_color {
    color: yellow;
  }


  .spacer10px {
    margin: 10px 0px;
  }

  .spacer20px {
    margin: 20px 0px;
  }

  .spacer30px {
    margin: 30px 0px;
  }

  .spacer40px {
    margin: 40px 0px;
  }

  .silom-font {
    font-family: Silom;
  }

  a {
    color: rgb(40, 40, 40);
  }

  p {
    font-size: 14px;
  }